<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="body">
      <a-table :columns="columns" :data-source="tableData" :scroll="{ y: 750 }" :rowKey="(record,index)=> index" :pagination="false" bordered>
        <template
            v-for="col in ['patroltype', 'managetype', 'linenumber','zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree']"
            :slot="col"
            slot-scope="text, record, index"
        >
          <div :key="col">
            <span v-if="col == 'patroltype'" style="margin: -5px 0" :value="text">{{patrolTypes[record.patroltype]?patrolTypes[record.patroltype].value:''}}</span>
            <span v-if="col == 'managetype'" style="margin: -5px 0" :value="text">{{record.managetype}}</span>
            <a v-if="col == 'linenumber'" style="width: 120px" @click="showSchemeModal(record)">{{record.linenumber}}</a>
            <a-select
                placeholder=""
                v-if="col == 'zero'"
                v-model="record.zero"
                :disabled="columnsDisabled.zero"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.zero)"
                :style="{width:65,color:(record.zero==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'one'"
                v-model="record.one"
                :disabled="columnsDisabled.one"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.one)"
                :style="{width:65,color:(record.one==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'two'"
                v-model="record.two"
                :disabled="columnsDisabled.two"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.two)"
                :style="{width:65,color:(record.two==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'three'"
                v-model="record.three"
                :disabled="columnsDisabled.three"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.three)"
                :style="{width:65,color:(record.three==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'four'"
                v-model="record.four"
                :disabled="columnsDisabled.four"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.four)"
                :style="{width:65,color:(record.four==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'five'"
                v-model="record.five"
                :disabled="columnsDisabled.five"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.five)"
                :style="{width:65,color:(record.five==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'six'"
                v-model="record.six"
                :disabled="columnsDisabled.six"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.six)"
                :style="{width:65,color:(record.six==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'seven'"
                v-model="record.seven"
                :disabled="columnsDisabled.seven"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.seven)"
                :style="{width:65,color:(record.seven==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'eight'"
                v-model="record.eight"
                :disabled="columnsDisabled.eight"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.eight)"
                :style="{width:65,color:(record.eight==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'nine'"
                v-model="record.nine"
                :disabled="columnsDisabled.nine"
                @change="statusChange(record,record.nine)"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                :style="{width:65,color:(record.nine==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'ten'"
                v-model="record.ten"
                :disabled="columnsDisabled.ten"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.ten)"
                :style="{width:65,color:(record.ten==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'eleven'"
                v-model="record.eleven"
                :disabled="columnsDisabled.eleven"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.eleven)"
                :style="{width:65,color:(record.eleven==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'twelve'"
                v-model="record.twelve"
                :disabled="columnsDisabled.twelve"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.twelve)"
                :style="{width:65,color:(record.twelve==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'thirteen'"
                v-model="record.thirteen"
                :disabled="columnsDisabled.thirteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.thirteen)"
                :style="{width:65,color:(record.thirteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'fourteen'"
                v-model="record.fourteen"
                :disabled="columnsDisabled.fourteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.fourteen)"
                :style="{width:65,color:(record.fourteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'fifteen'"
                v-model="record.fifteen"
                :disabled="columnsDisabled.fifteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.fifteen)"
                :style="{width:65,color:(record.fifteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'sixteen'"
                v-model="record.sixteen"
                :disabled="columnsDisabled.sixteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.sixteen)"
                :style="{width:65,color:(record.sixteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'seventeen'"
                v-model="record.seventeen"
                :disabled="columnsDisabled.seventeen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.seventeen)"
                :style="{width:65,color:(record.seventeen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'eighteen'"
                v-model="record.eighteen"
                :disabled="columnsDisabled.eighteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.eighteen)"
                :style="{width:65,color:(record.eighteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'nineteen'"
                v-model="record.nineteen"
                :disabled="columnsDisabled.nineteen"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.nineteen)"
                :style="{width:65,color:(record.nineteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'twenty'"
                v-model="record.twenty"
                :disabled="columnsDisabled.twenty"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.twenty)"
                :style="{width:65,color:(record.twenty==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'twentyone'"
                v-model="record.twentyone"
                :disabled="columnsDisabled.twentyone"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.twentyone)"
                :style="{width:65,color:(record.twentyone==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'twentytwo'"
                v-model="record.twentytwo"
                :disabled="columnsDisabled.twentytwo"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.twentytwo)"
                :style="{width:65,color:(record.twentytwo==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'twentythree'"
                :disabled="columnsDisabled.twentythree"
                v-model="record.twentythree"
                dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false"
                @change="statusChange(record,record.twentythree)"
                :style="{width:65,color:(record.twentythree==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
            </a-select>
          </div>
        </template>
      </a-table>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @upDate="change"></add-or-edit-modal>
    <add-or-edit-alarm-modal :visible.sync="alarmModalVisible" :show-type="alarmModalShowType" :detailData="alarmModalDetailData"></add-or-edit-alarm-modal>
    <patrol-scheme-modal :visible.sync="schemeModalVisible" :show-type="schemeModalShowType" :detailData="schemeModalDetailData"></patrol-scheme-modal>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import {addEngineerPatrolRecord, addEngineerPatrolRecordData, getEngineerPatrolSchemeTemplate} from "A/patrol";
import addOrEditModal from "V/patrols/patrolsRecord/abnormalRecord/addOrEditModal";
import addOrEditAlarmModal from "V/patrols/patrolsRecord/alarmRecord/addOrEditModal";
import moment from "moment";
import patrolSchemeModal from "V/material/engineerPatrolScheme/patrolSchemeModal";
import {patrolTypes} from "@/json/wlwhistory";
export default {
  name: "newPatrolRecord",
  components:{
    addOrEditModal,
    addOrEditAlarmModal,
    patrolSchemeModal
  },
  data() {
    return {
      moment,
      patrolTypes,
      time:['zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree'],
      styleLists:["width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;",
        "width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;",],
      columns:[
        {
          title: '序号',
          dataIndex: 'keynum',
          align: 'center',
          width: 40,
        },
        {
          title: '巡查方式',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 80,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          width: 160
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          width: 120
        },
      ],
      columnsDisabled:{
        zero:false,
        one:false,
        two:false,
        three:false,
        four:false,
        five:false,
        six:false,
        seven:false,
        eight:false,
        nine:false,
        ten:false,
        eleven:false,
        twelve:false,
        thirteen:false,
        fourteen:false,
        fifteen:false,
        sixteen:false,
        seventeen:false,
        eighteen:false,
        nineteen:false,
        twenty:false,
        twentyone:false,
        twentytwo:false,
        twentythree:false,
      },
      tableData:[],
      option:['正常','异常'],
      queryParams:{
        monitorpointnum:'',
      },
      patroltemplateid:0,
      monitorpointNameList:[],
      count:20,
      intervals:0,
      modalVisible: false,
      modalShowType: 'edit',
      modalDetailData: null,
      alarmModalVisible: false,
      alarmModalShowType: 'edit',
      alarmModalDetailData: null,
      schemeModalVisible: false,
      schemeModalShowType: 'edit',
      schemeModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "工程巡查记录"
    },
    breadcrumb() {
      const pages = [{name:"项目巡查与报警",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
  created() {
    if(this.$route.params.patrolrecordid){
      this.getMonitorpointName(this.$route.params.patrolrecordid)
    }else{
      this.getMonitorpointName()
    }
    // this.tableData=this.$store.state.patrolTableData
    // this.currentTime()
  },
  methods: {
    currentTime() {
      setTimeout(()=>{
        this.formatDate()
      },500)
      setInterval(this.formatDate, 10*60*1000);
    },
    formatDate() {
      //获取当前时间并打印
      let myDate = new Date()
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      for(let i=3;i<this.columns.length;i++){
        if(hou>parseInt(this.columns[i].title.split(":")[0])+(this.intervals===0?2:this.intervals===1?2:this.intervals*4)){
          this.columnsDisabled[this.columns[i].dataIndex]=true
        }else{
          this.columnsDisabled[this.columns[i].dataIndex]=false
        }
      }
    },
    change(msg) {},
    showSchemeModal(record){
      this.schemeModalVisible=true
      this.schemeModalDetailData=this.patrolschemeid
    },
    statusChange(record,time){
      if(time==2){
        this.modalDetailData={
          monitorpointnum:this.queryParams.monitorpointnum,
          eventrecordid:'',
          eventnum:'',
          managetype:record.managetype,
          eventcode:'6',
          eventstarttime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          starttime:'',
          eventtype:902,
          eventsource:"工程巡查（系统）",
          location:'',
          description:'',
          measure:'',
          repairorder:'',
          followreturn:'',
          finishsign:null,
          confirmman:this.userInfo.username,
          recordman:this.userInfo.username,
          eventstatus: null,
        }
        this.modalShowType='engineerPatrol'
        this.modalVisible=true
      }else if(time==3){
        this.alarmModalDetailData={
          eventrecordid:'',
          eventnum:'',
          eventname:'',
          managetype:record.managetype,
          systemnameid: '',
          eventstarttime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          eventtype:901,
          eventsource:"工程巡查（系统）",
          feedback:'',
          eventcode:'',
          monitorpointnum:this.queryParams.monitorpointnum,
          location:'',
          description:'',
          measure:'',
          biginfluence:1,
          repairorder:'无',
          plannedwork:0,
          timerange:'',
          followreturn:'',
          confirmman:this.userInfo.username,
          recordman:this.userInfo.username,
          arriveman:'',
          arrivetime:'',
          eventstatus: null,
        }
        this.alarmModalShowType='engineerPatrol'
        this.alarmModalVisible=true
      }
      this.$store.state.patrolTableData=this.tableData
      this.confirm('edit')
    },
    getMonitorpointName(patrolrecordid){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=this.$route.params.monitorpointnum?this.$route.params.monitorpointnum:res.item[0].monitorpointnum
          this.getEngineerPatrolSchemeTemplate(this.queryParams.monitorpointnum,patrolrecordid)
        }
      })
    },
    getEngineerPatrolSchemeTemplate(monitorpointnum,patrolrecordid){
      this.columns=[
        {
          title: '序号',
          dataIndex: 'keynum',
          align: 'center',
          width: 40
        },
        {
          title: '巡查方式',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 80,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          width: 160
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'linenumber' },
        },
      ]
      getEngineerPatrolSchemeTemplate({monitorpointnum:monitorpointnum,patrolrecordid: patrolrecordid,finishsign:0,patroltype:0}).then(res=>{
        if(res&&res.returncode==='0') {
          if(res.item.length===0){
            this.$message.error("未生成工程巡查模板或者巡查模板有问题，请通知管理员查看")
          }else{
            this.patrolschemeid=res.patrolschemeid
            let suffix = res.start.substring(3,5)
            let start = parseInt(res.start.substring(0,2))
            let end = suffix=="00"?parseInt(res.end.substring(0,2))+1:parseInt(res.end.substring(0,2))
            let intervals = res.intervals==0?1:res.intervals==1?2:4
            let lastHour=0
            if (start > end) {
              for (let i = start; i < 24; i+=intervals) {
                this.columns.push({
                  title: i + ':'+suffix,
                  dataIndex: this.time[i],
                  align: 'center',
                  scopedSlots: {customRender: this.time[i]},
                })
                if(i<24){lastHour=i}
              }
              for (let i = 0-(24-lastHour); i < end; i+=intervals) {
                if(i<0){continue}
                this.columns.push({
                  title: i + ':'+suffix,
                  dataIndex: this.time[i],
                  align: 'center',
                  scopedSlots: {customRender: this.time[i]},
                })
              }
            } else {
              for (let i = start; i < end; i+=intervals) {
                this.columns.push({
                  title: i + ':'+suffix,
                  dataIndex: this.time[i],
                  align: 'center',
                  scopedSlots: {customRender: this.time[i]},
                })
              }
            }
            this.tableData = res.item
            this.intervals = res.intervals
            this.currentTime()
            this.patroltemplateid=res.patroltemplateid
            this.$store.state.patrolrecordid=res.item[0].patrolrecordid
            if(!res.item[0].patrolrecordid){
              this.confirm('add')
            }
          }
        }else{
          this.$message.error("未生成工程巡查模板或者巡查模板有问题，请通知管理员查看")
        }
      })
    },
    handleAdd() {
      const { tableData } = this;
      const newData = {
        keynum: parseInt(this.tableData[this.tableData.length-1].keynum)+1,
        patroltype: 0,
        managetype: '随机抽查',
        linenumber: parseInt(this.tableData[this.tableData.length-1].linenumber)+1,
        zero:0,
        one:0,
        twp:0,
        three:0,
        four:0,
        five:0,
        six:0,
        seven:0,
        eight: 0,
        nine: 0,
        ten: 0,
        eleven: 0,
        twelve: 0,
        thirteen: 0,
        fourteen: 0,
        fifteen: 0,
        sixteen: 0,
        seventeen: 0,
        eighteen: 0,
        nineteen: 0,
        twenty: 0,
        twentyone: 0,
        twentytwo: 0,
        twentythree: 0,
      };
      this.tableData = [...tableData, newData];
    },
    handleDelete(){
      this.tableData.splice(this.tableData.length-1,1)
    },
    confirm(type){
      let params={
        monitorpointnum:this.queryParams.monitorpointnum,
        patroldate:moment(new Date()).format("YYYYMMDDHHmmss"),
        patroltemplateid:this.patroltemplateid,
        addoredit:type
      }
      this.showLoading();
      addEngineerPatrolRecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.id!=0&&res.id!=null&&res.id!=''){
            this.$store.state.patrolrecordid=res.id
            let params={
              monitorpointnum:this.queryParams.monitorpointnum,
              patrolschemeid:'',
              patrolrecordid:res.id?res.id:0,
              addoredit:type,
              finishsign:0,
              data:this.tableData,
            }
            addEngineerPatrolRecordData(params).then(res=>{
              if(res&&res.returncode=='0'){
                this.hideLoading();
                this.$message.success("保存成功")
              }
            })
          }else{
            let params={
              monitorpointnum:this.queryParams.monitorpointnum,
              patrolschemeid:'',
              patrolrecordid:this.$store.state.patrolrecordid?this.$store.state.patrolrecordid:0,
              addoredit:type,
              finishsign:0,
              data:this.tableData,
            }
            if(params.patrolrecordid!==null&&params.patrolrecordid!==0){
              addEngineerPatrolRecordData(params).then(res=>{
                if(res&&res.returncode=='0'){
                  this.hideLoading();
                  this.$message.success("保存成功")
                }
              })
            }else{
              this.hideLoading();
              this.$message.error("未生成工程巡查模板或者巡查模板有问题，请通知管理员查看")
            }
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.body{
  background-color: white;
  margin: 10px;
  width: 100%
}
</style>